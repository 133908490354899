import "./App.scss";
import React from "react";
import LoaderComponent from "../src/components/common/Loader/Loader";
import PreprodLogin from "./components/PreprodLogin/PreprodLogin";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";

const HomePage = React.lazy(() =>
  import("../src/pages/homepage/homepage.component")
);
const About = React.lazy(() =>
  import("../src/pages/about/aboutpage.component")
);
const NotFound = React.lazy(() =>
  import("../src/pages/not-found/not-found.component")
);
const Legal = React.lazy(() => import("../src/pages/legal/legal.component"));
const Privacy = React.lazy(() =>
  import("../src/pages/privacy/privacy.component")
);
const Terms = React.lazy(() => import("../src/pages/terms/terms.component"));
const TermsContests = React.lazy(() =>
  import("../src/pages/terms-contests/terms-contests.component")
);
const UserProfile = React.lazy(() =>
  import("../src/pages/user-profile/user-profile.component")
);
const UserVideo = React.lazy(() =>
  import("../src/pages/user-video/user-video.component")
);
const GreetingsListing = React.lazy(() =>
  import("../src/pages/greetings/greetingsHome")
);
const GreetingsDetail = React.lazy(() =>
  import("../src/pages/greetings/greetingsDetail")
);
const BrandAmbassadorComponent = React.lazy(() =>
  import("./pages/brand-ambassador/BrandAmbassadorComponent")
);

function App() {
  return (
    <div className="App">
      <React.Suspense fallback={<LoaderComponent />}>
        <Router>
          <PreprodLogin />
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/greetings" component={GreetingsListing} />
            <Route
              exact
              path="/greetings/:category"
              component={GreetingsListing}
            />
              <Route
              exact
              path="/greetings/:category/:userId"
              component={GreetingsDetail}
            />
            <Route exact path="/gyan" component={GreetingsListing} />
            <Route exact path="/motivation" component={GreetingsListing} />
            <Route exact path="/gyan/:userId" component={GreetingsDetail} />
            <Route
              exact
              path="/motivation/:userId"
              component={GreetingsDetail}
            />
            <Route
              exact
              path="/brand-ambassador"
              component={BrandAmbassadorComponent}
            />
            <Route exact path="/about" component={About} />
            <Route exact path="/legal" component={Legal} />
            <Route exact path="/privacy" component={Privacy} />
            <Route exact path="/terms" component={Terms} />
            <Route exact path="/terms-contests" component={TermsContests} />
            <Route exact path="/:lang(hindi)?/:id" component={UserProfile} />
            <Route exact path="/:storyType/:id" component={UserVideo} />
            <Route component={NotFound} />
          </Switch>
        </Router>
      </React.Suspense>
    </div>
  );
}

export default App;
